// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as Badge from "../styleguide/components/Badge/Badge.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as PervasivesU from "rescript/lib/es6/pervasivesU.js";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function statusFromString(string) {
  switch (string) {
    case "approved" :
        return "Approved";
    case "blocked" :
        return "Blocked";
    case "pending" :
        return "Pending";
    default:
      return PervasivesU.failwith("Unknown status");
  }
}

function statusToString(status) {
  switch (status) {
    case "Pending" :
        return "pending";
    case "Approved" :
        return "approved";
    case "Blocked" :
        return "blocked";
    
  }
}

function fromJs(js) {
  return {
          id: js.id,
          ipAddress: js.ipAddress,
          status: js.status,
          invalidAttempts: js.invalidAttempts,
          blockedAt: js.blockedAt,
          createdAt: js.createdAt,
          updatedAt: js.updatedAt
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              ipAddress: field.required("ipAddress", Json_Decode$JsonCombinators.string),
              status: field.required("status", Json_Decode$JsonCombinators.string),
              invalidAttempts: field.required("invalidAttempts", Json_Decode$JsonCombinators.$$int),
              blockedAt: Js_null_undefined.fromOption(field.required("blockedAt", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string))),
              createdAt: field.required("createdAt", Json_Decode$JsonCombinators.string),
              updatedAt: field.required("updatedAt", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

function fromJs$1(js) {
  return {
          id: js.id,
          ipAddress: js.ipAddress,
          status: js.status,
          invalidAttempts: js.invalidAttempts,
          createdAt: js.createdAt
        };
}

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              ipAddress: field.required("ipAddress", Json_Decode$JsonCombinators.string),
              status: field.required("status", Json_Decode$JsonCombinators.string),
              invalidAttempts: field.required("invalidAttempts", Json_Decode$JsonCombinators.$$int),
              createdAt: field.required("createdAt", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$1(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$1));
}

function fromJs$2(js) {
  return {
          id: js.id,
          email: js.email,
          status: js.status,
          invalidAttempts: js.invalidAttempts
        };
}

var decoder$2 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              email: field.required("email", Json_Decode$JsonCombinators.string),
              status: field.required("status", Json_Decode$JsonCombinators.string),
              invalidAttempts: field.required("invalidAttempts", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJs$3(js) {
  return {
          suspiciousEmails: Belt_Array.map(js.suspiciousEmails, fromJs$2),
          totalSuspiciousEmails: js.totalSuspiciousEmails,
          totalPages: js.totalPages,
          currentPage: js.currentPage,
          perPage: js.perPage
        };
}

var decoder$3 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              suspiciousEmails: field.required("suspiciousEmails", Json_Decode$JsonCombinators.array(decoder$2)),
              totalSuspiciousEmails: field.required("totalSuspiciousEmails", Json_Decode$JsonCombinators.$$int),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              perPage: field.required("perPage", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson$2(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$3));
}

var Index = {
  fromJs: fromJs$3,
  decoder: decoder$3,
  fromJson: fromJson$2
};

var SuspiciousEmail = {
  fromJs: fromJs$2,
  decoder: decoder$2,
  Index: Index
};

function fromJs$4(js) {
  return {
          id: js.id,
          firstName: js.firstName,
          lastName: js.lastName,
          email: js.email
        };
}

var decoder$4 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              firstName: field.required("firstName", Json_Decode$JsonCombinators.string),
              lastName: field.required("lastName", Json_Decode$JsonCombinators.string),
              email: field.required("email", Json_Decode$JsonCombinators.string)
            };
    });

function fromJs$5(js) {
  return {
          users: Belt_Array.map(js.users, fromJs$4),
          totalUsers: js.totalUsers,
          totalPages: js.totalPages,
          currentPage: js.currentPage,
          perPage: js.perPage
        };
}

var decoder$5 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              users: field.required("users", Json_Decode$JsonCombinators.array(decoder$4)),
              totalUsers: field.required("totalUsers", Json_Decode$JsonCombinators.$$int),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              perPage: field.required("perPage", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson$3(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$5));
}

var Index$1 = {
  fromJs: fromJs$5,
  decoder: decoder$5,
  fromJson: fromJson$3
};

var User = {
  fromJs: fromJs$4,
  decoder: decoder$4,
  Index: Index$1
};

var Dashboard = {
  fromJs: fromJs$1,
  decoder: decoder$1,
  fromJson: fromJson$1,
  SuspiciousEmail: SuspiciousEmail,
  User: User
};

function SuspiciousIp$Badge(props) {
  switch (props.status) {
    case "Pending" :
        return JsxRuntime.jsx(Badge.Span.make, {
                    id: "suspiciousIpStatus",
                    color: "Gray",
                    children: "Pending"
                  });
    case "Approved" :
        return JsxRuntime.jsx(Badge.Span.make, {
                    id: "suspiciousIpStatus",
                    color: "Blue",
                    children: "Approved"
                  });
    case "Blocked" :
        return JsxRuntime.jsx(Badge.Span.make, {
                    id: "suspiciousIpStatus",
                    color: "Yellow",
                    children: "Blocked"
                  });
    
  }
}

var Badge$1 = {
  make: SuspiciousIp$Badge
};

var Edit = {
  fromJs: fromJs,
  decoder: decoder,
  fromJson: fromJson
};

export {
  statusFromString ,
  statusToString ,
  fromJs ,
  decoder ,
  fromJson ,
  Dashboard ,
  Badge$1 as Badge,
  Edit ,
}
/* decoder Not a pure module */
